<template>
  <div>
    <b-table
      id="passengerTable"
      :items="passengers"
      :fields="tableColumns"
      primary-key="id"
      empty-text="No matching records found"
      responsive
      show-empty
      class="position-relative"
    >
      <template #cell(name)="data">
        <span class="text-capitalize">{{ data.item.prefix }} {{ data.item.first_name }} {{ data.item.last_name }}</span>
      </template>
      <template
        #cell(e-ticket)="data"
      >
        <span
          v-if="productData.ticket && productData.ticket[data.item.traveller_id]"
          :id="data.item.traveller_id"
          class="text-capitalize"
        >
          {{ productData.ticket[data.item.traveller_id] }}
        </span>
        <template v-else>
          <b-button
            :id="data.item.traveller_id"
            size="sm"
            variant="outline-primary"
          >
            + ADD
          </b-button>
        </template>
        <div>
          <b-popover
            :target="data.item.traveller_id"
            placement="left"
            triggers="click"
          >
            <template #title>
              {{ productData.booking_id }}
            </template>
            <b-form @submit.prevent>
              <b-form-group
                label="E-Ticket"
                :label-for="data.item.traveller_id"
              >
                <b-form-input
                  :id="data.item.id"
                  v-model="eticketNum"
                />
              </b-form-group>
            </b-form>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end mt-1"
              >
                <b-button
                  variant="outline-primary"
                  size="sm"
                  class="mr-1"
                  @click="hidePopoverBox(data.item.id)"
                >
                  Close
                </b-button>
                <b-button
                  ref="confirmationApplyBtn"
                  variant="primary"
                  size="sm"
                  @click="addEticketNumber(productData.id, data.item.traveller_id)"
                >
                  <b-spinner
                    v-if="isUpdating"
                    small
                  />
                  Apply
                </b-button>
              </b-col>
            </b-row>
          </b-popover>
        </div>
      </template>
      <template #cell(passport_img)="data">
        <a
          v-if="data.item.passport_img"
          :href="data.item.passport_img"
          target="_blank"
        >
          <b-img
            :src="data.item.passport_img"
            alt="passport image"
            fluid
          />
        </a>
      </template>
      <template #cell(visa_img)="data">
        <a
          v-if="data.item.visa_img"
          :href="data.item.visa_img"
          target="_blank"
        >
          <b-img
            :src="data.item.visa_img"
            alt="passport image"
            fluid
          />
        </a>
      </template>
    </b-table>
  </div>
</template>

<script>
import helper from '@/mixins/helper'
import api from '@/libs/api'

export default {
  name: 'PassengerList',
  mixins: [helper],
  props: {
    passengers: {
      type: Array,
    },
    productData: {
      type: Object,
    },
  },
  data() {
    return {
      eticketNum: '',
      isUpdating: false,
      tableColumns: [
        {
          key: 'name', label: 'Name', sortable: true, thClass: 'lg',
        },
        { key: 'code', label: 'Code' },
        { key: 'e-ticket', thClass: 'md' },
        {
          key: 'date_of_birth', label: 'Date Of Birth', formatter: value => this.formatDate(value), thClass: 'md',
        },
        { key: 'phone', label: 'Phone' },
        { key: 'email', label: 'Email' },
        {
          key: 'passport_number', label: 'Passport Number', formatter: value => (value || 'N/A'), thClass: 'lg',
        },
        {
          key: 'passport_expiry_date', label: 'Passport Expiry Date', formatter: value => (value ? this.formatDate(value) : 'N/A'), thClass: 'lg',
        },
        { key: 'passport_country', label: 'Passport Country', thClass: 'md' },
        { key: 'passport_img', label: 'Passport Image', thClass: 'sm' },
        { key: 'visa_img', label: 'Visa Image', thClass: 'sm' },
      ],
    }
  },
  methods: {
    hidePopoverBox(id) {
      this.$root.$emit('bv::hide::popover', id)
      this.eticketNum = ''
    },
    addEticketNumber(bookingID, travellerID) {
      this.isUpdating = true
      api.updateData(`flight/admin_services/booking_ticket_update/${bookingID}/`, true, {
        pax_id: travellerID,
        ticket_no: this.eticketNum,
      }).then(response => {
        if (response.data.status) {
          this.$emit('getBookingList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'E-ticket has been added successfully')
          this.hidePopoverBox(travellerID)
          this.productData.ticket[travellerID] = response.data.result.ticket_no
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isUpdating = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
