<template>
  <b-sidebar
    id="flight-details-view"
    ref="flight-sidebar"
    width="55%"
    title="Flight Details"
    bg-variant="white"
    right
    backdrop
    no-header
    @shown="getInvoiceData"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Flight Details
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="pb-1"
      >
        <b-tabs
          pills
          class="mt-1"
        >
          <b-tab
            title-link-class="ml-1"
            active
            class="p-1"
          >
            <template #title>
              <feather-icon
                icon="GitBranchIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Flights</span>
            </template>
            <FlightCard
              :trip="flightData"
              :invoice="flightData.invoice"
            />
          </b-tab>
          <b-tab
            title-link-class="ml-1"
          >
            <template #title>
              <feather-icon
                icon="GitBranchIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Passengers</span>
            </template>
            <passenger-list
              :passengers="flightData.paxes"
              :product-data="flightData"
            />
          </b-tab>
          <b-tab v-if="invoiceData && Object.keys(invoiceData).length !== 0">
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Invoice</span>
            </template>
            <div class="pl-1">
              <invoice
                :invoice="invoiceData"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import PassengerList from '@/components/flight/PassengerList.vue'
import FlightCard from '@/components/flight/FlightCard.vue'
import Invoice from '@/components/common/Invoice.vue'
import api from '@/libs/api'

export default {
  name: 'FlightBookingDetails',
  components: {
    PassengerList,
    FlightCard,
    Invoice,
  },
  props: {
    flightData: {
      type: Object,
    },
  },
  data() {
    return {
      invoiceData: {},
    }
  },
  methods: {
    getInvoiceData() {
      if (this.flightData.invoice && this.flightData.invoice.invoice_id) {
        let url = `user_journey/admin_services/invoice_list/?search=${this.flightData.invoice.invoice_id}`
        if (this.flightData.invoice.invoice_id.startsWith('BEI')) {
          url = `b2e_dashboard/invoice/?search=${this.flightData.invoice.invoice_id}`
        }
        api.getData(url, true).then(response => {
          if (response.data.status) {
            // eslint-disable-next-line prefer-destructuring
            this.invoiceData = response.data.result.results[0]
          } else {
            this.invoiceData = this.flightData.invoice
          }
        }).catch()
      } else {
        this.invoiceData = this.flightData.invoice
      }
    },
  },
}
</script>

<style scoped>

</style>
