import { render, staticRenderFns } from "./PassengerList.vue?vue&type=template&id=2423813b&scoped=true&"
import script from "./PassengerList.vue?vue&type=script&lang=js&"
export * from "./PassengerList.vue?vue&type=script&lang=js&"
import style0 from "./PassengerList.vue?vue&type=style&index=0&id=2423813b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2423813b",
  null
  
)

export default component.exports